<template>
  <div class="headerWrap">
    <div class="header">
      <div class="title">
        <!-- <img class="logo" :src="require('@/assets/logo.png')" alt="" /> -->
        <img class="logo" :src="`/${sysLogo}`" alt="" />
        <div class="name">{{sysName}} （{{stageText}}）</div>
      </div>
      <div class="operation" v-if="$route.path != '/login' ">
        <div class="item" v-if="userName == '' || userName == undefined">
          已有账号，
          <router-link to="/login">
            去登录 <a-icon type="right" />
          </router-link>
        </div>
        <div class="item" v-else>
          <a-dropdown placement="bottomCenter">
            <a class="ant-dropdown-link">
              <a-icon class="userIcon" type="user" />
              {{ userName }}
              <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <div @click="toUserLogin">退出登录</div>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
      <div class="operation" v-if="$route.path == '/login' ">
       <!-- @click="back" -->
        <router-link to="/enter">
          <div class="back">
            <a-icon type="arrow-left" />
            返回上一级
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { logOut } from '@/api/api';

export default {
  data() {
    return {
      // year: new Date().getFullYear(),
      userName: "",
      stageText: "",
      sysName: localStorage.getItem("sysName"),
      sysLogo: localStorage.getItem("sysLogo")
    };
  },
  mounted() {
    let userInfo = JSON.parse(localStorage.getItem("user"));
    console.log(userInfo);
    if (userInfo) {
      this.userName = userInfo.userName;
    }
    this.stageText = localStorage.getItem("stageText")
  },
  updated(){
    this.getUserName()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    toUserLogin() {
      logOut().then(res => {
        if (res.code == 0) {
          this.$message.success(res.description);
          localStorage.removeItem("user");
          localStorage.removeItem("stage");
          localStorage.removeItem("stageText");
          localStorage.removeItem("bmStageId");
          sessionStorage.removeItem("twiceStatus")
          setTimeout(() => {
            this.$router.push({
              name: "Enter",
            });
          }, 1000)
        } else {
          this.$message.error(res.description);
        }
      })
    },
    getUserName(){
      let userInfo = JSON.parse(localStorage.getItem("user"));
      console.log(userInfo);
      if (userInfo) {
        this.userName = userInfo.userName;
      }
    }
  },
};
</script>
<style lang="less" scoped>
.headerWrap {
  padding: 34px 0;
}
.header {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title{
    display: flex;
    .logo{
      width: 24px;
      height: 24px;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0,0,0,0.27);
    }
    .name{
      font-size: 18px;
      font-weight: 600;
      color: #333;
      margin-left: 10px;
    }
  }
  .operation {
    display: flex;
    .item {
      position: relative;
      .userIcon {
        position: absolute;
        left: -20px;
        top: 3px;
      }
    }
    .back{
      width: 144px;
      height: 34px;
      line-height: 32px;
      text-align: center;
      border-radius: 18px;
      border: solid 1px #35ab61;
      font-size: 16px;
      color: #35ab61;
      cursor: pointer;
      margin-right: 34px;
    }
  }
}
</style>
